import React from "react"
import PDF from "../images/resume1.pdf"
const ResumePage = () => {
  return (
    <div>
      <iframe src={PDF} height="1200px" width="100%"></iframe>
    </div>
  )
}
export default ResumePage
